import { localizedCountryName } from '@pmi.web/countries';
import {
  ArrowIcon,
  BoldHeading,
  disableUIBlocker,
  PageLayout,
  SimpleButton,
} from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts';
import { errorToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { IMandator } from 'main/schemas/Mandator';
import { ParentComponent } from 'main/schemas/ParentComponent';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { setClarityTag } from 'main/services/clarity';
import SepaService from 'main/services/directdebit/SepaService';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import { getMandatorInformation } from 'main/services/mandators/MandatorsApi';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { formatPrice } from 'main/utils/formatters';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SepaMandateConsentDetails } from './SepaMandateConsentDetails';

export const SepaMandateConsentPage: FC<ParentComponent> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const paymentModuleT = useUserFriendlyPaymentModuleName();

  const [authorizeChecked, setAuthorizeChecked] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const [mandator, setMandator] = React.useState<IMandator>();

  const customerNumber = AuthenticationService.user.tpId;

  if (PaymentStore.legalEntity === undefined) {
    throw new Error('legal entity shoule be set');
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthorizeChecked(event.target.checked);
  };

  useEffect(() => {
    disableUIBlocker();

    if (PaymentStore.legalEntity === undefined) {
      throw new Error('legal entity should be set');
    }

    getMandatorInformation(PaymentStore.legalEntity)
      .then((mandatorInfo) => {
        setMandator(mandatorInfo);
      })
      .catch((e: Error) => {
        TelemetryService.trackException(e, 'SepaMandateConsentPage');
        Logger.error(
          LoggerOrigin.ReactComponent,
          'Mandator info fetch failure',
          e
        );
        setError(e);
      });
  }, []);

  const styles = {
    pageContentHeader:
      'sticky top-0 bg-primary p-sm md:p-md 2xl:p-lg z-50 text-white',
    container: 'flex gap-4 relative focused-container items-start',
    checkbox: 'mt-2 ml-1 text-12 scale-125',
  };

  const headerText = useMemo(() => {
    return t('Total: {{totalPrice}}', {
      totalPrice: formatPrice(
        language as SupportedLocale,
        PaymentStore.currency,
        PaymentStore.orderTotal
      ),
    });
  }, [t, language]);

  if (error) {
    return (
      <AlertWarning
        title={t('Something went wrong!')}
        description={t('SEPA payments are currently unavailable.')}
      />
    );
  }

  return (
    <PageLayout
      headerDescription={
        PaymentStore.orderNumber
          ? `${t('Order')}: ${PaymentStore.orderNumber}`
          : undefined
      }
    >
      <div className={styles.pageContentHeader}>
        <BoldHeading>{headerText}</BoldHeading>
      </div>
      <div className="m-sm md:m-md">
        <div>
          <SimpleButton
            onClick={() =>
              NavigationService.navigateTo({
                page: InternalPage.PaymentMethods,
              })
            }
          >
            <span className={'mt-2 flex flex-row items-center justify-center'}>
              <ArrowIcon direction="left" />
              <span className={'ml-xs'}>{t('Back')}</span>
            </span>
          </SimpleButton>
        </div>
        <br />
        <b>{t('Account Holder')}</b>
        <table>
          <thead>
            <tr>
              <td className="table-cell">{t('First name/Last Name')}</td>
              <td className="table-cell">{PaymentStore.customerName}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table-cell">{t('Customer Number')}</td>
              <td className="table-cell">{customerNumber}</td>
            </tr>
            <tr>
              <td className="table-cell">{t('Email')}</td>
              <td className="table-cell">{PaymentStore.customerEmail}</td>
            </tr>
            <tr>
              <td className="table-cell">{t('Street and house number')}</td>
              <td className="table-cell">{PaymentStore.customerStreet}</td>
            </tr>
            <tr>
              <td className="table-cell">{t('ZIP/City')}</td>
              <td className="table-cell">{PaymentStore.customerCity}</td>
            </tr>
            <tr>
              <td className="table-cell">{t('Country')}</td>
              <td className="table-cell">
                {localizedCountryName(
                  PaymentStore.customerCountryCode,
                  language
                )}
              </td>
            </tr>
            <tr>
              <td className="table-cell">{t('IBAN')}</td>
              <td className="table-cell">
                {PaymentStore.directDebitMandate?.iban}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        {mandator && (
          <div>
            <b>{t('Payee')}</b>
            <table>
              <thead>
                <tr>
                  <td className="table-cell">{t('Company')}</td>
                  <td className="table-cell"> {mandator.companyName}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-cell">{t('Creditor ID')}</td>
                  <td className="table-cell">{mandator.iban}</td>
                </tr>
                <tr>
                  <td className="table-cell">{t('Street and house number')}</td>
                  <td className="table-cell">{mandator.addressline1}</td>
                </tr>
                <tr>
                  <td className="table-cell">{t('ZIP/City')}</td>
                  <td className="table-cell">
                    {mandator.zip} {mandator?.locality}
                  </td>
                </tr>
                <tr>
                  <td className="table-cell">{t('Country')}</td>
                  <td className="table-cell">
                    {localizedCountryName(mandator.country ?? '', language)}
                  </td>
                </tr>
                <tr>
                  <td className="table-cell">{t('Mandate type')}</td>
                  <td className="table-cell">
                    {t('SEPA basic mandate for repeated use')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <br />
        <div className={styles.container}>
          <input
            type="checkbox"
            id="consentCheckbox"
            className={styles.checkbox}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="consentCheckbox">
            <div
              className={`overflow-y-auto ${
                authorizeChecked ? 'h-auto' : 'h-36'
              } `}
            >
              <SepaMandateConsentDetails />
            </div>
          </label>
        </div>
        <br />
        <div className={'flex flex-row items-center justify-center wrap'}>
          <br />
          {
            <PayButton
              title={t('Buy now')}
              disabled={!authorizeChecked}
              onClick={() => {
                const currentPage = NavigationService.getInternalPage();

                NavigationService.navigateTo({
                  page: InternalPage.StatusProcessing,
                });
                const sepaMandate = PaymentStore.directDebitMandate;

                if (sepaMandate === undefined) {
                  throw new Error('sepa mandate information is missing');
                }

                SepaService.submitSepaForm(
                  sepaMandate.customerName ?? '',
                  sepaMandate.iban
                )
                  .then(() => {
                    NavigationService.navigateTo({
                      page: InternalPage.StatusSuccess,
                    });
                    setClarityTag('pay', [
                      RedirectStatus.Success,
                      paymentModuleT(PaymentModuleId.Sepa),
                    ]);
                  })
                  .catch((err) => {
                    NavigationService.navigateTo(currentPage);
                    TelemetryService.trackException(
                      err,
                      'SepaMandateConsentPage'
                    );
                    Logger.error(
                      LoggerOrigin.ReactComponent,
                      'Sepa Mandate creation failed',
                      err
                    );
                    errorToast(
                      t('Something went wrong!'),
                      t('Failed to authorize SEPA Mandate.')
                    );
                    setClarityTag('pay', [
                      RedirectStatus.Failure,
                      paymentModuleT(PaymentModuleId.Sepa),
                    ]);
                  });
              }}
            />
          }
        </div>
      </div>
    </PageLayout>
  );
};
